import { CoButton } from "components/CoButton";
import useLabels from "hooks/useLabels";

type PageNotFoundProps = {
  error?: Error | undefined;
};

function PageNotFound({ error = new Error("404 error.") }: PageNotFoundProps) {
  const [pageLabels] = useLabels("screens.notfound");

  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <i className="text-6xl text-accent-base icon-traffic-cone" />
      <div className="text-center">
        <h1 className="text-3xl font-bold">{pageLabels["title"]}</h1>
        <p className="mt-3 text-fg-muted">{pageLabels["instructions"]}</p>
      </div>
      <CoButton
        href="/search"
        target="_blank"
        label={pageLabels["back-to-home"]}
      />
      <p className="text-sm text-fg-faint">{error.message}</p>
    </div>
  );
}

export default PageNotFound;
