import PageNotFound from "components/ErrorPage/PageNotFound";

function Error404() {
  return (
    <div className="flex px-4 sm:px-6 md:px-0 justify-center mt-4 mb-28 md:mt-20">
      <PageNotFound />
    </div>
  );
}

export default Error404;
